<script setup lang="ts">
import Modal from '@/components/Modal.vue'
import UpsellModalProduct from '@/components/UpsellModalProduct.vue'

import { Product } from '@/services/storefront'
import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'

withDefaults(
  defineProps<{
    productLoadState?: AsyncOperationState
    isBusy: boolean
    upsellProduct: Product | null
  }>(),
  {
    productLoadState: AsyncOperationState.DONE,
  }
)

const emit = defineEmits<{
  (e: 'close'): void
  (
    e: 'addToOrder',
    productId: string,
    variantId: string,
    quantity: number,
    planId: string | null
  ): void
}>()

const onAddToOrder = (
  productId: string,
  variantId: string,
  quantity: number,
  planId: string | null
) => {
  emit(
    'addToOrder',
    productId.replace('gid://shopify/Product/', ''),
    variantId.replace('gid://shopify/ProductVariant/', ''),
    quantity,
    planId ? planId.replace('gid://shopify/SellingPlan/', '') : null
  )
}
</script>

<template>
  <Modal @close="emit('close')">
    <p v-if="productLoadState === AsyncOperationState.IN_PROGRESS">
      Loading product info…
    </p>
    <Message
      mode="warning"
      v-else-if="productLoadState === AsyncOperationState.ERRORED"
    >
      <p>There was an error loading product info. Please try again.</p>
    </Message>
    <UpsellModalProduct
      v-else-if="upsellProduct"
      :product="upsellProduct"
      :is-busy="isBusy"
      @add-to-order="onAddToOrder"
    />
  </Modal>
</template>
