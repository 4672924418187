import type { ImageMap } from '@/stores/productImage'

export async function getImagesForProduct(id: string, variantId?: string) {
  const res = await fetch(
    `https://${
      import.meta.env.VITE_SHOPIFY_STORE
    }.myshopify.com/api/2022-10/graphql.json`,
    {
      method: 'POST',
      headers: {
        'X-Shopify-Storefront-Access-Token': import.meta.env
          .VITE_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        query Product($id: ID!) {
          product(id: $id) {
            id
            variants(first: 10) {
              edges {
                node {
                  id
                  title
                  image {
                    altText
                    small: url(transform: { maxWidth: 200 })
                    medium: url(transform: { maxWidth: 500 })
                    large: url(transform: { maxWidth: 1000 })
                    original: url
                  }
                }
              }
            }
            images(first: 1) {
              edges {
                node {
                  altText
                  width
                  height
                  small: url(transform: { maxWidth: 200 })
                  medium: url(transform: { maxWidth: 500 })
                  large: url(transform: { maxWidth: 1000 })
                  original: url
                }
              }
            }
          }
        }
      `,
        variables: {
          id: `gid://shopify/Product/${id}`,
        },
      }),
    }
  )
  if (res.ok) {
    const product = (await res.json()).data.product
    if (product) {
      if (variantId) {
        return product.variants.edges
          .filter((edge: { node: { id: string } }) => {
            if (
              variantId.toString() ===
              edge.node.id.replace('gid://shopify/ProductVariant/', '')
            ) {
              return true
            }
          })
          .map((variant: { node: { image: ImageMap } }) => variant.node.image)
      } else {
        return product.images.edges.map(
          (edge: { node: ImageMap }) => edge?.node
        )
      }
    } else {
      return null
    }
  } else {
    return null
  }
}

export async function getHandleForProduct(id: string) {
  const res = await fetch(
    `https://${
      import.meta.env.VITE_SHOPIFY_STORE
    }.myshopify.com/api/2022-10/graphql.json`,
    {
      method: 'POST',
      headers: {
        'X-Shopify-Storefront-Access-Token': import.meta.env
          .VITE_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        query Product($id: ID!) {
          product(id: $id) {
            id,
            handle
          }
        }
      `,
        variables: {
          id: `gid://shopify/Product/${id}`,
        },
      }),
    }
  )
  if (res.ok) {
    const product = (await res.json()).data.product
    return product
  } else {
    return null
  }
}

export async function getProduct(id: string) {
  const res = await fetch(
    `https://${
      import.meta.env.VITE_SHOPIFY_STORE
    }.myshopify.com/api/2022-10/graphql.json`,
    {
      method: 'POST',
      headers: {
        'X-Shopify-Storefront-Access-Token': import.meta.env
          .VITE_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        query Product($id: ID!) {
          product(id: $id) {
            ${productGraphQlFragment}
          }
        }
      `,
        variables: {
          id: `gid://shopify/Product/${id}`,
        },
      }),
    }
  )
  if (res.ok) {
    const product = (await res.json()).data.product
    return product as Product
  } else {
    return null
  }
}

export async function getProducts(ids: string[]) {
  const res = await fetch(
    `https://${
      import.meta.env.VITE_SHOPIFY_STORE
    }.myshopify.com/api/2022-10/graphql.json`,
    {
      method: 'POST',
      headers: {
        'X-Shopify-Storefront-Access-Token': import.meta.env
          .VITE_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        query Products($ids: [ID!]!) {
          nodes(ids: $ids) {
            ... on Product {
              ${productGraphQlFragment}
            }
          }
        }
      `,
        variables: {
          ids: ids.map((id) => `gid://shopify/Product/${id}`),
        },
      }),
    }
  )
  if (res.ok) {
    const products = ((await res.json()).data.nodes as Product[]).filter((n) =>
      Boolean(n)
    )
    return products
  } else {
    return null
  }
}

export async function getProductVariantSkus(
  ids: string[]
): Promise<{ id: string; sku: string }[] | null> {
  const res = await fetch(
    `https://${
      import.meta.env.VITE_SHOPIFY_STORE
    }.myshopify.com/api/2022-10/graphql.json`,
    {
      method: 'POST',
      headers: {
        'X-Shopify-Storefront-Access-Token': import.meta.env
          .VITE_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        query ProductVariant($ids: [ID!]!) {
          nodes(ids: $ids) {
            ... on ProductVariant {
              id
              sku
            }
          }
        }
      `,
        variables: {
          ids: ids.map((id) => `gid://shopify/ProductVariant/${id}`),
        },
      }),
    }
  )
  if (res.ok) {
    const product = (await res.json()).data.nodes
    return product
  } else {
    return null
  }
}

const productGraphQlFragment = `
  id
  title
  availableForSale
  compatible_dispenser: metafield(namespace: "collection_filter", key: "compatible_dispenser") {
    value
  }
  tags
  handle
  images(first: 1) {
    edges {
      node {
        altText
        small: url(transform: { maxWidth: 200 })
        medium: url(transform: { maxWidth: 500 })
        large: url(transform: { maxWidth: 1000 })
        original: url
      }
    }
  }
  sellingPlanGroups(first: 2) {
    edges {
      node {
        name
        sellingPlans(first: 10) {
          edges {
            node {
              id
              name
              priceAdjustments {
                adjustmentValue {
                  ... on SellingPlanPercentagePriceAdjustment {
                    adjustmentPercentage
                  }
                }
              }
            }
          }

        }
      }
    }
  }
  variants(first: 10) {
    edges {
      node {
        id
        title
        image {
          altText
          small: url(transform: { maxWidth: 200 })
          medium: url(transform: { maxWidth: 500 })
          large: url(transform: { maxWidth: 1000 })
          original: url
        }
        price {
          amount
        }
        sellingPlanAllocations(first:10) {
          edges {
            node {
              sellingPlan {
                id
                name
                priceAdjustments {
                  adjustmentValue {
                    ... on SellingPlanPercentagePriceAdjustment {
                      adjustmentPercentage
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export async function getProductByVariant(variantId: string) {
  const res = await fetch(
    `https://${
      import.meta.env.VITE_SHOPIFY_STORE
    }.myshopify.com/api/2022-10/graphql.json`,
    {
      method: 'POST',
      headers: {
        'X-Shopify-Storefront-Access-Token': import.meta.env
          .VITE_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `query Products($variantId: ID!) {
          node(id: $variantId) {
            ... on ProductVariant {
              id
              product {
                ${productGraphQlFragment}
              }
            }
          }
        }`,
        variables: {
          variantId,
        },
      }),
    }
  )
  if (res.ok) {
    const resJson = await res.json()
    return {
      id: resJson.data.node.id,
      product: {
        ...resJson.data.node.product,
        images: resJson.data.node.product.images.edges[0].node,
        variants: resJson.data.node.product.variants.edges.map(
          (e: { node: unknown }) => e.node
        ),
      },
    }
  } else {
    return null
  }
}

export async function getDispensers(type: string) {
  const res = await fetch(
    `https://${
      import.meta.env.VITE_SHOPIFY_STORE
    }.myshopify.com/api/2022-10/graphql.json`,
    {
      method: 'POST',
      headers: {
        'X-Shopify-Storefront-Access-Token': import.meta.env
          .VITE_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          query DispensersForType($dispenserType: String!) {
            collection(handle: "dispensers") {
              products(first: 10, filters: {
                  productMetafield: {
                    namespace: "collection_filter",
                    key: "fil_dispenser_type",
                    value: $dispenserType
                  }
                }) {
                edges {
                  node {
                    id
                    title
                    images(first: 1) {
                      edges {
                        node {
                          altText
                          small: url(transform: { maxWidth: 200 })
                          medium: url(transform: { maxWidth: 500 })
                          large: url(transform: { maxWidth: 1000 })
                          original: url
                        }
                      }
                    }
                    variants(first: 10) {
                      edges {
                        node {
                          id
                          title
                          image {
                            altText
                            small: url(transform: { maxWidth: 200 })
                            medium: url(transform: { maxWidth: 500 })
                            large: url(transform: { maxWidth: 1000 })
                            original: url
                          }
                          price {
                            amount
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }`,
        variables: {
          dispenserType: type,
        },
      }),
    }
  )
  if (res.ok) {
    const product = (await res.json()).data.collection.products.edges.map(
      (e: {
        node: {
          images: { edges: [{ node: unknown }] }
          variants: { edges: { node: unknown }[] }
        }
      }) => {
        return {
          ...e.node,
          images: e.node.images.edges[0].node,
          variants: e.node.variants.edges.map((e) => e.node),
        }
      }
    )
    return product
  } else {
    return null
  }
}

export async function getFragranceGroupProducts(fragranceGroupId: string) {
  const res = await fetch(
    `https://${
      import.meta.env.VITE_SHOPIFY_STORE
    }.myshopify.com/api/2022-10/graphql.json`,
    {
      method: 'POST',
      headers: {
        'X-Shopify-Storefront-Access-Token': import.meta.env
          .VITE_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          query FragranceGroupProducts($fragranceGroup: String!) {
            collection(handle: "all") {
              products(first: 10, filters: {
                  productMetafield: {
                    namespace: "product_meta",
                    key: "fragrance_group_id",
                    value: $fragranceGroup
                  }
                }) {
                edges {
                  node {
                    id
                    title
                    fragrance_name: metafield(namespace: "product_meta", key: "fragrance_name") {
                      value
                    }
                    images(first: 1) {
                      edges {
                        node {
                          altText
                          small: url(transform: { maxWidth: 200 })
                          medium: url(transform: { maxWidth: 500 })
                          large: url(transform: { maxWidth: 1000 })
                          original: url
                        }
                      }
                    }
                    variants(first: 10) {
                      edges {
                        node {
                          id
                          price {
                            amount
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }`,
        variables: {
          fragranceGroup: fragranceGroupId,
        },
      }),
    }
  )
  if (res.ok) {
    const product = (await res.json()).data.collection.products.edges.map(
      (e: {
        node: {
          images: { edges: [{ node: unknown }] }
          variants: { edges: { node: unknown }[] }
        }
      }) => {
        return {
          ...e.node,
          images: e.node.images.edges[0].node,
          variants: e.node.variants.edges.map((e) => e.node),
        }
      }
    )
    return product
  } else {
    return null
  }
}

export async function getProductFragranceGroup(id: string) {
  const res = await fetch(
    `https://${
      import.meta.env.VITE_SHOPIFY_STORE
    }.myshopify.com/api/2022-10/graphql.json`,
    {
      method: 'POST',
      headers: {
        'X-Shopify-Storefront-Access-Token': import.meta.env
          .VITE_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        query Product($id: ID!) {
          product(id: $id) {
            id,
            handle,
            fragrance_group_id: metafield(namespace: "product_meta", key: "fragrance_group_id") {
              value
            }
          }
        }
      `,
        variables: {
          id: `gid://shopify/Product/${id}`,
        },
      }),
    }
  )
  if (res.ok) {
    const product = (await res.json()).data.product
    return product
  } else {
    return null
  }
}

export interface Product {
  id: string
  title: string
  availableForSale: boolean
  tags: string[]
  handle: string
  compatible_dispenser: {
    key: string
    value: string
  }
  images: {
    edges: {
      node: {
        altText: string
        small: string
        medium: string
        large: string
        original: string
      }
    }[]
  }
  sellingPlanGroups: {
    edges: {
      node: {
        name: string
        sellingPlans: {
          edges: {
            node: {
              id: string
              name: string
              priceAdjustments: {
                adjustmentValue: {
                  adjustmentPercentage: number
                }
              }[]
            }
          }[]
        }
      }
    }[]
  }
  variants: {
    edges: {
      node: {
        id: string
        title: string
        image: {
          altText: string
          small: string
          medium: string
          large: string
          original: string
        }
        price: {
          amount: string
        }
        sellingPlanAllocations: {
          edges: {
            node: {
              sellingPlan: {
                id: string
                name: string
                priceAdjustments: {
                  adjustmentValue: {
                    adjustmentPercentage: number
                  }
                }[]
              }
            }
          }[]
        }
      }
    }[]
  }
}
