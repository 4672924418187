import fetch from './lib/fetch'

export interface RecommendationItem {
  variant_id: string
  product_id: string
  handle: string
}

export async function getRecommendations(
  shopifyId: string,
  rechargeId: string | undefined,
  algorithm: string,
  context: string[]
): Promise<RecommendationItem[]> {
  const qs = new URLSearchParams({
    customer_id: shopifyId,
  })
  if (rechargeId) qs.append('recharge_id', rechargeId)
  for (const c of context) qs.append('context', c)
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/recommendations/${algorithm}?${qs.toString()}`
  )
}
