import { getRecommendations } from '@/services/recommendation'
import Bugsnag from '@bugsnag/browser'
import { defineStore } from 'pinia'
import { AsyncOperationState } from './lib/AsyncOperationState'
import { type Product, getProducts } from '@/services/storefront'

const initialState = () => ({
  loadState: AsyncOperationState.IDLE,
  items: [] as Product[],
  stale: true,
})

type AlgorithmKey = 'buy_again' | 'new'

export const useRecommendationStore = defineStore('recommendation', {
  state: () => ({
    buy_again: initialState(),
    new: initialState(),
  }),
  actions: {
    async init(
      shopifyId: string,
      rechargeId: string | undefined,
      algorithm: AlgorithmKey,
      context: string[]
    ) {
      if (
        (this[algorithm].stale ||
          this[algorithm].loadState === AsyncOperationState.IDLE) &&
        shopifyId
      ) {
        await this.load(shopifyId, rechargeId, algorithm, context)
      }
    },
    async load(
      shopifyId: string,
      rechargeId: string | undefined,
      algorithm: AlgorithmKey,
      context: string[]
    ) {
      this[algorithm].loadState = AsyncOperationState.IN_PROGRESS
      try {
        const recommendations = await getRecommendations(
          shopifyId,
          rechargeId,
          algorithm,
          context
        )
        const products = await getProducts(
          recommendations.map((r) => r.product_id)
        )
        if (products) {
          this[algorithm].items = products
          this[algorithm].loadState = AsyncOperationState.DONE
          this[algorithm].stale = false
        } else {
          throw new Error('Could not load products')
        }
      } catch (e) {
        Bugsnag.notify(e as Error)
        this[algorithm].loadState = AsyncOperationState.ERRORED
      }
    },
  },
})
