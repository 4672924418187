<script lang="ts" setup>
import { ref, computed } from 'vue'
import type { Component } from 'vue'
import Button from './Button.vue'
import Message from './Message.vue'

import { t } from '@/lib/locale'

const props = withDefaults(
  defineProps<{
    quantity: number
    isWorking: boolean
    maxQuantity?: number
    floatingMesage?: {
      text: string
      Icon: Component
    }
  }>(),
  {
    maxQuantity: 10,
  }
)

const emit = defineEmits<{
  (e: 'saveChanges', changes: { quantity: number }): void
}>()

const hasChanges = computed(() => props.quantity !== newQuantity.value)
const newQuantity = ref(props.quantity)

const onQuantityChange = (event: Event) => {
  newQuantity.value = parseInt((event.target as HTMLSelectElement).value)
}

const hasFloatingMessage = props.floatingMesage
  ? 'OnetimeManager--has-floating-message'
  : ''

const save = () =>
  emit('saveChanges', {
    quantity: newQuantity.value,
  })
</script>

<template>
  <div class="OnetimeManager Card" :class="hasFloatingMessage">
    <h3 class="OnetimeManager__Title h4">
      {{ t('purchase_item_onetime_manager_title') }}
    </h3>
    <p class="OnetimeManager__Updates">
      {{ t('purchase_item_onetime_manager_prefix') }}
      <span class="OnetimeManager__LineTwo">
        <select @change="onQuantityChange" :disabled="isWorking">
          <option
            v-for="n in maxQuantity"
            :value="n"
            :selected="quantity === n"
            :key="n"
          >
            {{ n }}
          </option>
        </select>
        {{ t('purchase_item_onetime_manager_midfix') }}
      </span>
    </p>
    <div>
      <Button
        :disabled="!hasChanges || isWorking"
        :modifiers="['inverted']"
        @click="save"
        >{{ t('purchase_item_onetime_manager_save_cta') }}</Button
      >
    </div>
    <div class="OnetimeManager__FloatingMessage" v-if="floatingMesage">
      <Message mode="mid" pointerPosition="topleft" pointerDirection="vertical">
        <template #icon>
          <component :is="floatingMesage.Icon" class="Icon" />
        </template>
        {{ floatingMesage.text }}
      </Message>
    </div>
  </div>
</template>

<style>
.OnetimeManager {
  padding: 24px;
  position: relative;
}

.OnetimeManager--has-floating-message {
  padding-bottom: 35px;
}

.OnetimeManager__Title {
  margin: 0;
}

.OnetimeManager__Updates {
  margin: 8px 0 16px;
}

.OnetimeManager__LineTwo {
  display: block;
  margin-top: 8px;
  @media (--mq-tablet-and-up) {
    display: inline;
    margin-top: 0;
  }
}

.OnetimeManager .Message--error {
  margin-top: 16px;
}

.OnetimeManager__FloatingMessage {
  position: absolute;
  bottom: 20px;
  left: 25px;
  transform: translateY(100%);
  max-width: 465px;
}
</style>
